<template>
    <v-row justify="center" class="cardes">

    <v-col
      cols="10"
      sm="8"
      md="6"
      lg="4"
    >

    <v-progress-linear
      v-if="isLoading"
      indeterminate
      color="blue darken-2"
    ></v-progress-linear>
      <v-card ref="form" elevation="5" class="rounded-lg pa-8" >

        <div align="center" class="pt-8 pb-3">
          <v-img :src="require('../assets/logo.png')" width="50%"  />
        </div>
        <v-card-text>
            <v-text-field
                outlined
                dense
                  
                color="rgb(200, 200, 200)"
                label="نوع الحساب"
                v-model="username"
                      ></v-text-field>


        <v-text-field

            :append-icon="isPassShow ? 'mdi-eye' : 'mdi-eye-off'"
            outlined
                dense
                filled  
                color="rgb(200, 200, 200)"
                label="كلمة المرور"
            :type="isPassShow ? 'text' : 'password'"
            name="input-10-2"
            v-model="passwordModel"
            value=""
            
            @click:append="isPassShow = !isPassShow"
          ></v-text-field>
        </v-card-text>

       <div align="center">
         <v-btn

                width="90%"
             :disabled="isLoading"
             :loading="isLoading"
             class="white--text pa-5 mb-12"
             color="blue darken-2"
             @click="login"
         >
           دخول
         </v-btn>
       </div>
      </v-card>
      <v-alert
          class="mt-5"
          left
          border="left"
          color="red"
          dismissible
          elevation="9"
          type="error"
          v-model="isShow"
      >{{msgError}}</v-alert>
    </v-col>
  </v-row>


 
</template>

<script>

import AuthService from '../services/AuthService.js';
import PermissionsManagement from "@/services/PermissionsManagement";
  export default {
    
    data () {
      return {
        isPassShow: false,
        username: '',
        passwordModel: '',
        isLoading: false,
        isShow: false,
        msgError:"خطاء في اسم المستخدم او كلمة المرور",
      }
    },
    methods: {
    async login() {
      try {
        this.isLoading =true;

        const credentials = {
          'username': this.username,
          'password': this.passwordModel
        };

    const response = await AuthService.login(credentials);

        const token = response.token;
        const user = response.user;
        if(token==null)
        {
          this.isShow = true;
          this.isLoading =false;
          this.passwordModel = "";
        }else{
          this.passwordModel = "";
          this.$store.dispatch('login', { token, user });

          this.$store.dispatch('setRoles',  await PermissionsManagement.initPermissionRole());
          this.$forceUpdate();
          this.$router.push('/');
        }



      } catch (error) {
        this.isShow = true;
        this.isLoading =false;
        // this.msg = error.response.data.msg;

      }
    }
  },

    watch: {
      isLoading (val) {
        // if (!val) return
        // setTimeout(() => (this.isLoading = false), 4000)
      },
    },
   
  }
</script>

<style scoped>
.v-text-field--outlined >>> fieldset {
  border-color: rgba(202, 202, 202, 0.986);

}

.cardes
{
  margin-top:5%;
}
</style>
