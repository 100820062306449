import axios from 'axios';
import configraions from "@/configuration/dbConfig";


const url = `${configraions.url}/api/v1`;


export default {
 
  login(credentials) {

    return axios
      .post(url + '/auth/', credentials,{
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      })
      .then(response => response.data);
  },

  getSecretContent() {
    return axios.get(url + 'secret-route/').then(response => response.data);
  }
};